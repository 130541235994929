import { Configuration, ReportRetryRequest, ReportsApi as ReportsRestApi } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter';
import type {
    ReportsApi,
    ReportConfigRepresentation,
    ReportConfigCreateRequest,
    ReportConfigUpdateRequest,
    ReportCreateRequest,
} from '@/modules/shared/adapter/ReportsApi';
import { AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

interface ReportConfigWrapper {
    key: string;
    config: ReportConfigRepresentation;
}

export class CachedReportingRestApi implements ReportsApi {
    private readonly reportsApi: ReportsRestApi;
    private readonly reportConfigCache: IndexedDBRepository<string, ReportConfigWrapper>;
    private localOverwrites: ReportConfigRepresentation[] = [];

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.reportsApi = new ReportsRestApi(restApiConfig);
        this.reportConfigCache = new IndexedDBRepository(indexedDb, 'report-configs');
    }

    public async createReportConfig(createSpec: ReportConfigCreateRequest): Promise<ReportConfigRepresentation> {
        const savedReportConfig = await this.reportsApi.createReportConfig(createSpec);
        await this.reportConfigCache.save({ key: savedReportConfig.dashboardKey, config: savedReportConfig });
        return savedReportConfig;
    }

    public async getReportConfigByDashboardKey(dashboardKey: string): Promise<ReportConfigRepresentation|undefined> {
        const cached = await this.reportConfigCache.findByKey(dashboardKey);
        if (cached) {
            return cached.config;
        }
        const reportConfigsForDashboard = await this.reportsApi.getReportConfigs(dashboardKey);
        if (reportConfigsForDashboard.length > 1) {
            console.warn(`More than on report configs found for dashboard ${dashboardKey}. Using first.`);
        }
        await this.reportConfigCache.save({ key: dashboardKey, config: reportConfigsForDashboard[0] });
        return reportConfigsForDashboard[0];
    }

    public async updateReportConfig(configKey: string, updateSpec: ReportConfigUpdateRequest): Promise<ReportConfigRepresentation> {
        // this.localOverwrites.filter((overwrite) => overwrite.key !== configKey);
        const savedReportConfig = await this.reportsApi.updateReportConfig(configKey, updateSpec);
        await this.reportConfigCache.save({ key: savedReportConfig.dashboardKey, config: savedReportConfig });
        return savedReportConfig;
    }

    public async getLocalOverwriteByDashboardKey(dashboardKey: string): Promise<ReportConfigRepresentation|undefined> {
        return this.localOverwrites.find((overwrite) => overwrite.dashboardKey === dashboardKey);
    }

    public async createLocalOverwrite(config: ReportConfigRepresentation): Promise<ReportConfigRepresentation> {
        // remove existing overwrites for this config
        this.localOverwrites = this.localOverwrites.filter((ow) => ow.key !== config.key);
        this.localOverwrites.push(config);
        return config;
    }

    public async clearLocalOverwrites(): Promise<void> {
        this.localOverwrites = [];
    }

    public async removeLocalOverwriteForDashboard(dashboardKey: string): Promise<void> {
        this.localOverwrites = this.localOverwrites.filter((config) => config.dashboardKey !== dashboardKey);
    }

    public async generateReport(request: ReportCreateRequest): Promise<string> {
        const reportJob = await this.reportsApi.startReportGeneration(request);
        return reportJob.reportId;
    }

    public async retryReport(reportRetryRequest: ReportRetryRequest): Promise<void> {
        await this.reportsApi.retryReport(reportRetryRequest);
    }
}
